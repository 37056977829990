import React, { useState } from 'react';
import { FieldError, useFormContext, UseFormRegister } from 'react-hook-form';

import type { UsersUsersItemServiceUser } from '@jobandtalent/auth-api-client-v2/dist/apiClient/model';
import { Checkbox, InputText, Select } from '@jobandtalent/design-system';

import FormInput from 'src/components/FormInput/FormInput';
import Section from 'src/components/Section/Section';


export type ServiceUserSectionType = {
  register: UseFormRegister<{
    service_user_flag: boolean | undefined
    owner: string | undefined
    description: string | undefined
    account_type: string | undefined
    var_path: string | undefined
  }>;
  errors: {
    errorsid?: FieldError | undefined;
    service_user_flag?: FieldError | undefined;
    owner?: FieldError | undefined;
    description?: FieldError | undefined;
    account_type?: FieldError | undefined;
    var_path?: FieldError | undefined;
  };
};

const ServiceUserSection = ({ service_user_items }: { service_user_items: UsersUsersItemServiceUser | undefined; }) => {
  const service_user_flag = service_user_items?.id != null;
  
  const default_option = () => {
    switch (service_user_items?.account_type) {
      case 'bot':
        return { value: 'bot', label: 'Bot' };
      case 'service_to_service':
        return { value: 'service_to_service', label: 'Service to service' };
      case 'lambda_to_service':
        return { value: 'lambda_to_service', label: 'Lambda to service' };
      default:
        return { value: '', label: '' };
    }
  };

  const { register, formState: { errors }, setValue } = useFormContext();
  const [account_type, setAccountType] = useState(default_option());

  const handleSelectChange = (newValue: any, _actionMeta: any) => {
    setAccountType(newValue);
    setValue('account_type', newValue.value);
  }

  return (
    <Section icon="info" title="Service User info" defaultCollapsed={!service_user_flag}>
      <FormInput label="">
        <Checkbox
          checked={service_user_flag}
          disabled={service_user_flag}
          label="Service User"
          name="service_user_flag"
          id="service_user_flag"
        />
      </FormInput>
      <FormInput label="Owner">
        <InputText placeholder="Owner" error={!!errors.owner} {...register('owner', {required: service_user_flag})} />
      </FormInput>
      <FormInput label="Description">
        <InputText placeholder="Description" error={!!errors.description} {...register('description', {required: service_user_flag})}/>
      </FormInput>
      <FormInput label="Var path">
        <InputText placeholder="Var path" error={!!errors.var_path} {...register('var_path', {required: service_user_flag})}/>
      </FormInput>
      <FormInput label="Service user type: bot, service_to_service, lambda_to_service">
          <Select error={!!errors.account_type}
            onChange={handleSelectChange}
            name="account_type"
            id="account_type"
            value={account_type}
            options={[
              { value: 'bot', label: 'Bot' }, 
              { value: 'service_to_service', label: 'Service to service' }, 
              { value: 'lambda_to_service', label: 'Lambda to service' }
            ]}
          />
      </FormInput>
    </Section>
  );
};

export default ServiceUserSection;
