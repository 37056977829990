import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { registerNewUser } from '@jobandtalent/auth-api-client-v2';
import { RegisterNewUser200, RegisterNewUserBodyUserServiceUserInfoAccountType } from '@jobandtalent/auth-api-client-v2/dist/apiClient/model';
import { Checkbox, ErrorMessage, InputText, Select } from '@jobandtalent/design-system';

import FormInput from 'src/components/FormInput/FormInput';
import Section from 'src/components/Section/Section';
import StackView, { SaveCancelFooter } from 'src/components/StackView/StackView';
import useError, { DEFAULT_ERROR } from 'src/hooks/useError/useError';
import useLocalPermissions from 'src/hooks/useLocalPermissions/useLocalPermissions';

import { useUserState } from '../UserState';
import useUsersRouter from '../useUsersRouter';


const NewUserForm = () => {
  const { resetUserSelection } = useUserState();
  const { updateQuery } = useUsersRouter();
  const { permissions, error: permissionError } = useLocalPermissions();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      time_zone: '',
      locale: '',
      phone_number: '',
      country_code: '',
      service_user: false,
      service_user_info: {
        owner: '',
        description: '',
        account_type: '',
        var_path: ''
      }
    }
  });

  const { error, setError } = useError();
  const onClose = resetUserSelection;

  const onSubmit = async (data: { 
    name: string; 
    email: string, 
    time_zone: string, 
    locale: string, 
    phone_number: string, 
    service_user: boolean, 
    service_user_info: {
      owner: string, 
      description: string, 
      var_path: string,
      account_type: string } 
    }) => {
    registerNewUser({ user: { 
      name: data.name, 
      email: data.email, 
      time_zone: data.time_zone, 
      locale: data.locale,
      service_user: serviceUserFlag, 
      service_user_info: {
        owner: data.service_user_info.owner,
        description: data.service_user_info.description,
        account_type: account_type,
        var_path: data.service_user_info.var_path
      } }}).then(
      (response: RegisterNewUser200) => {
        updateQuery(response.data.user.name);
        resetUserSelection();
      },
      (error) => {
        setError(error?.response?.data ? error?.response?.data : DEFAULT_ERROR);
      }
    );
  };

  const [serviceUserFlag, setServiceUserFlag] = useState(false);
  const [account_type, setAccountType] = useState<RegisterNewUserBodyUserServiceUserInfoAccountType>();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceUserFlag(event.target.checked);
    const required = event.target.checked ? true : false;
    register('service_user_info.owner', { required });
    register('service_user_info.description', { required });
    register('service_user_info.var_path', { required });
  };
  const handleSelectChange = (newValue: any, _actionMeta: any) => {
    setAccountType(newValue?.value as RegisterNewUserBodyUserServiceUserInfoAccountType);
  }
  const ServiceUsersPermissions = !permissionError && permissions.serviceUsersPermission;
  const StackViewFooter = <SaveCancelFooter onClose={onClose} onSelect={handleSubmit(onSubmit)} />;

  return (
    <StackView footer={StackViewFooter} onClose={onClose} title="New user">
      <FormInput label="User name">
        <InputText error={!!errors.name} {...register('name', { required: true })} />
        {!!errors.name && <ErrorMessage>{errors.name.type}</ErrorMessage>}
      </FormInput>
      <FormInput label="User email">
        <InputText error={!!errors.email} {...register('email', { required: true })} />
        {!!errors.email && <ErrorMessage>{errors.email.type}</ErrorMessage>}
      </FormInput>
      <FormInput label="User locale">
        <InputText placeholder="Supported currently en-GB or es-ES"  error={!!errors.locale} {...register('locale', { required: false })} />
        {!!errors.locale && <ErrorMessage>{errors.locale.type}</ErrorMessage>}
      </FormInput>
      <FormInput label="User time zone">
        <InputText placeholder="Europe/Madrid, Europe/London, ..." error={!!errors.time_zone} {...register('time_zone', { required: false })} />
        {!!errors.time_zone && <ErrorMessage>{errors.time_zone.type}</ErrorMessage>}
      </FormInput>
      {ServiceUsersPermissions && 
      <Section icon="info" title="Service User info" defaultCollapsed={true}>
        <FormInput label="Service User">
          <Checkbox
            {...register('service_user', { required: false })}
            disabled={false}
            checked={serviceUserFlag}
            onChange={handleCheckboxChange}
            label="Service or Bot User"
            name="Service User"
            id="service_user"
          />
        </FormInput>
        <FormInput label="Service user owner">
            <InputText placeholder="Service user group owner" error={!!errors.service_user_info?.owner} {...register('service_user_info.owner', { required: false })} />
            {!!errors.service_user_info?.owner && <ErrorMessage>{errors.service_user_info.owner.type}</ErrorMessage>}
        </FormInput>
        <FormInput label="Service user description">
            <InputText placeholder="Service user description" error={!!errors.service_user_info?.description} {...register('service_user_info.description', { required: false })} />
            {!!errors.service_user_info?.description && <ErrorMessage>{errors.service_user_info.description.type}</ErrorMessage>}
        </FormInput>
        <FormInput label="Service user var path">
            <InputText placeholder="Service user var path" error={!!errors.service_user_info?.var_path} {...register('service_user_info.var_path', { required: false })} />
            {!!errors.service_user_info?.var_path && <ErrorMessage>{errors.service_user_info.var_path.type}</ErrorMessage>}
        </FormInput>
        <FormInput label="Service user type: bot, service_to_service, lambda_to_service">
            <Select error={!!errors.service_user_info?.account_type} 
              {...register('service_user_info.account_type', { required: false })}
              onChange={handleSelectChange}
              isMulti={false}
              isClearable={false}
              name="account_type"
              id="account_type"
              options={[
                { value: 'bot', label: 'Bot' }, 
                { value: 'service_to_service', label: 'Service to service' }, 
                { value: 'lambda_to_service', label: 'Lambda to service' }
              ]}
            />
            {!!errors.service_user_info?.account_type && <ErrorMessage>{errors.service_user_info.account_type.type}</ErrorMessage>}
        </FormInput>
      </Section>
      }
      {error}
    </StackView>
  );
};

export default NewUserForm;