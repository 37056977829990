import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  AXIOS_INSTANCE,
  createUserPermission,
  updateGrantedPermissionToUser
} from '@jobandtalent/auth-api-client-v2';
import {
  FetchPermissions200,
  Permission
} from '@jobandtalent/auth-api-client-v2/dist/apiClient/model';
import { Select, Textarea } from '@jobandtalent/design-system';

import useSWRInfinite from 'swr/infinite';

import FormInput from 'src/components/FormInput/FormInput';
import StackView, { SaveCancelFooter } from 'src/components/StackView/StackView';
import useError, { DEFAULT_ERROR } from 'src/hooks/useError/useError';

import { PermissionFormProps, PermissionOption, TextareaValue } from '../Users.types';

const formatContent = (content: string): string => {
  return content.replace(/, */g, ',\n');
};

const calculateHeight = (displayValue: string, defaultHeight: string): string => {
  const textarea = document.createElement('textarea');
  textarea.style.visibility = 'hidden';
  textarea.style.position = 'absolute';
  textarea.style.height = 'auto';
  textarea.style.whiteSpace = 'pre';
  textarea.value = displayValue;

  document.body.appendChild(textarea);
  const calculatedHeight = Math.max(textarea.scrollHeight, parseInt(defaultHeight));
  document.body.removeChild(textarea);

  return `${calculatedHeight}px`;
};

const fetcher = (input: RequestInfo): Promise<FetchPermissions200> => {
  return AXIOS_INSTANCE({ url: input as string, method: 'GET' }).then((res) => res.data);
};

const getKey = (pageIndex: number, previousPageData: FetchPermissions200) => {
  // reached the end
  if (previousPageData && !previousPageData.meta.next_page === null) {
    return null;
  }

  return `/permissions?page=${pageIndex + 1}&per_page=100`; // SWR key
};
import { formatDataScopesData,formatDataScopesInput } from 'src/common/dataScopesHelper';
import useLocalPermissions from 'src/hooks/useLocalPermissions/useLocalPermissions';

const PermissionForm = ({
  onClose,
  onSuccess,
  selectedUser,
  selectedPermission
}: PermissionFormProps) => {
  const {
    data: permissionsData,
    error: permissionsError,
    size,
    setSize
  } = useSWRInfinite<FetchPermissions200>(getKey, fetcher, {
    revalidateFirstPage: false
  });
  const { error, setError } = useError();
  const [permissionOptions, setPermissionOptions] = useState<PermissionOption[]>([]);
  const { control, handleSubmit } = useForm();
  const { permissions, error: permissionError } = useLocalPermissions();
  const companyAdminPermission = !permissionError && permissions.companyAdminPermission;

  useEffect(() => {
    if (permissionsData) {
      // if there's still something to fetch, fetch it before setting select options
      if (permissionsData.at(-1)?.meta.next_page !== null) {
        setSize(size + 1);
      } else {
        const data = permissionsData
        .reduce(
          (acc: Permission[], permissionsPage) => [...acc, ...permissionsPage.data.permissions],
          []
        )
        .map(({ id, name, scope_name, app }) => ({
          value: id,
          label: `${scope_name}:${name}`,
          app: app
        }))

        const filteredOptions = !companyAdminPermission
        ? data.filter(option => !option.app)
        : data;

        setPermissionOptions(
          filteredOptions
        );
      }
    }
  }, [permissionsData, companyAdminPermission]);

  useEffect(() => {
    if (permissionsError) {
      setError(permissionsError);
    }
  }, [permissionsError]);

  const onSubmit = async (data: { permissionId: PermissionOption; resourceIds: TextareaValue; dataScopes: TextareaValue; }) => {
    if (selectedPermission) {
      updateGrantedPermissionToUser(selectedUser.id, selectedPermission.id, {
        user_permission: userPermissionsData(data)
      }).then(onSuccess, (error) => {
        setError(error?.response?.data?.message ? error?.response?.data : DEFAULT_ERROR);
      });
    } else {
      createUserPermission(selectedUser.id, {
        user_permission: userPermissionsData(data)
      }).then(onSuccess, (error) => {
        setError(error?.response?.data?.message ? error?.response?.data : DEFAULT_ERROR);
      });
    }
  };

  type PermissionFormDataTypeWithoutDataScopes = {
    permission_id: number;
    resource_ids: string;
  };

  type PermissionFormDataTypeWithDataScopes = {
    permission_id: number;
    resource_ids: string;
    data_scope: string[];
  };

  const userPermissionsData = (data: { permissionId: PermissionOption; resourceIds: TextareaValue; dataScopes: TextareaValue; }): PermissionFormDataTypeWithDataScopes | PermissionFormDataTypeWithoutDataScopes => {
    return {
      permission_id: data.permissionId.value,
      resource_ids: data.resourceIds.value,
      data_scope: formatDataScopesData(data.dataScopes.value)
    }

    return {
      permission_id: data.permissionId.value,
      resource_ids: data.resourceIds.value
    }
  }
  
  interface KeyValuePair {
    key: string;
    value: string;
  }

  const dataScope = selectedPermission ? formatDataScopesInput(selectedPermission.data_scope) : '';

  const [dataPairs, setDataPairs] = useState<KeyValuePair[]>([]);
  const [dataScopeValue, setDataScopeValue] = useState<string>(dataScope);

  useEffect(() => {
    const parsedData = parseDataScopesInput(dataScope);
    setDataPairs(parsedData);
  }, [dataScope]);

  const [shouldRenderDataPairs, setShouldRenderDataPairs] = useState(false);

  const parseDataScopesInput = (input: string): KeyValuePair[] => {
    return input.split(',').map(pair => {
      const [key, value] = pair.split(':').map(s => s.trim());
      return { key, value };
    });
  };

  const handleTextareaChange = (value: string) => {
    const newValue = value;
    const newData = parseDataScopesInput(newValue);
    setDataPairs(newData);
  };

  const handlePairChange = (index: number, keyOrValue: 'key' | 'value', newValue: string) => {
    const newData = dataPairs.map((pair, i) => {
      if (i === index) {
        return { ...pair, [keyOrValue]: newValue };
      }
      return pair;
    });
    setDataPairs(newData);
    setDataScopeValue(newData.map(pair => `${pair.key}:${pair.value}`).join(','));
  };

  /* @ts-expect-error: verify onSubmit typing */
  const StackViewFooter = <SaveCancelFooter onClose={onClose} onSelect={handleSubmit(onSubmit)} />;
  const keyValuePattern = /\w+:\w+/;
  return (
    <StackView
      footer={StackViewFooter}
      onClose={onClose}
      title={selectedPermission ? 'Edit permission' : 'Grant new permission'}
      nested={true}
    >
      <FormInput label="Permission name">
        {permissionOptions.length ? (
          <Controller
            name="permissionId"
            control={control}
            defaultValue={selectedPermission ? selectedPermission.id : false}
            render={({ field: { onChange } }) => (
              <Select
                onChange={onChange}
                options={permissionOptions}
                isDisabled={!!selectedPermission}
                defaultValue={
                  selectedPermission
                    ? permissionOptions.find((option) => option.value === selectedPermission.id)
                    : undefined
                }
              />
            )}
          />
        ) : null}
      </FormInput>
      <Controller
        name="resourceIds"
        control={control}
        render={({ field: { onChange } }) => (
          <Textarea
            height={87}
            label="Resource IDs"
            onChange={onChange}
            value={selectedPermission ? selectedPermission.resource_ids : ''}
            placeholder="Resource IDs must be separated by commas. i.e., value1, value2, value3"
          />
        )}
      />

      <Controller
        name="dataScopes"
        control={control}
        render={({ field: { onChange } }) => (
          <div>
            <label htmlFor="data" className="ds-text-body-base-semibold">Data Scopes</label>
            {shouldRenderDataPairs && dataPairs.map((pair, index) => (
              <div className="data-scope-block" key={index}>
                <input
                  name="data"
                  type="text"
                  value={pair.key}
                  onChange={(e) => handlePairChange(index, 'key', e.target.value)}
                  disabled={!!(selectedPermission?.app && !companyAdminPermission)}
                />
                <input
                  type="text"
                  value={pair.value}
                  onChange={(e) => handlePairChange(index, 'value', e.target.value)}
                  disabled={!!(selectedPermission?.app && !companyAdminPermission)}
                />
              </div>
            ))}
            <Textarea
              name="data-scope"
              height={calculateHeight(formatContent(dataScope), "87")}
              onChange={(e) => {
                handleTextareaChange(e.value);
                onChange(e);
                if (keyValuePattern.test(e.value)) {
                  setShouldRenderDataPairs(true);
                } else {
                  setShouldRenderDataPairs(false);
                }
              }}
              value={formatContent(dataScopeValue ? dataScopeValue : dataScope)}
              placeholder="Data scopes must be separated by commas. i.e., company_id:1, company_id:2, country:ES"
              disabled={!!(selectedPermission?.app && !companyAdminPermission)}
            />
          </div>
        )}
      />

      {error}
    </StackView>
  );
};

export default PermissionForm;
